<template>
  <div>
    <!-- label -->
    <div class="tw-inline-flex tw-items-center tw-my-2">
      <svg class="tw-h-6 tw-w-6" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
        <g>
          <path d="M36,10.9c4.3,0,8.3,1.1,11.8,3.1c-0.1-0.6-0.2-1.2-0.2-1.8c0-0.5,0-0.9,0.1-1.4C44.2,9.2,40.2,8.2,36,8.2
            c-4.2,0-8.2,1-11.8,2.7c0.1,0.4,0.1,0.9,0.1,1.3c0,0.6-0.1,1.2-0.2,1.8C27.7,12.1,31.7,10.9,36,10.9z"/>
          <path d="M60,34.9C60,34.9,60,34.9,60,34.9c0,9.1-5,17-12.5,21.1c0.7,0.7,1.3,1.4,1.8,2.2c8-4.6,13.5-13.3,13.5-23.2c0,0,0,0,0-0.1
            H60z"/>
          <path d="M24.5,56C17,51.9,12,44,12,35c0,0,0,0,0-0.1H9.2c0,0,0,0,0,0.1c0,9.9,5.4,18.6,13.5,23.2C23.2,57.4,23.8,56.7,24.5,56z"/>
          <path d="M72,30.8c-0.1-5.4-3.6-10-8.4-11.9c2-1.5,3.4-4,3.4-6.7c0-4.6-3.7-8.4-8.4-8.4c-4.6,0-8.4,3.7-8.4,8.4
            c0,2.7,1.3,5.2,3.4,6.7c-4.9,1.9-8.4,6.6-8.4,12.1c0,0.7,0.6,1.3,1.3,1.3h24.2c0,0,0,0,0,0c0.7,0,1.3-0.6,1.3-1.3
            C72,30.9,72,30.9,72,30.8z M52.8,12.2c0-3.2,2.6-5.8,5.8-5.8s5.8,2.6,5.8,5.8S61.8,18,58.6,18C55.4,18,52.8,15.4,52.8,12.2z
            M47.9,29.7c0.7-5.1,5.2-9.1,10.7-9.1s10.1,4,10.7,9.1H47.9z"/>
          <path d="M25.5,32.3c0.7,0,1.3-0.6,1.3-1.3c0-0.1,0-0.2,0-0.2c-0.1-5.4-3.6-10-8.4-11.9c2-1.5,3.4-4,3.4-6.7c0-4.6-3.7-8.4-8.4-8.4
            C8.8,3.9,5,7.6,5,12.2c0,2.7,1.3,5.2,3.4,6.7C3.5,20.9,0,25.6,0,31c0,0.7,0.6,1.3,1.3,1.3H25.5C25.5,32.3,25.5,32.3,25.5,32.3z
            M7.6,12.2c0-3.2,2.6-5.8,5.8-5.8s5.8,2.6,5.8,5.8S16.6,18,13.4,18C10.2,18,7.6,15.4,7.6,12.2z M2.7,29.7c0.7-5.1,5.2-9.1,10.7-9.1
            s10.1,4,10.7,9.1H2.7z"/>
          <path d="M41,54.7c2-1.5,3.4-4,3.4-6.7c0-4.6-3.7-8.4-8.4-8.4c-4.6,0-8.4,3.7-8.4,8.4c0,2.7,1.3,5.2,3.4,6.7
            c-4.9,1.9-8.4,6.6-8.4,12.1c0,0.7,0.6,1.3,1.3,1.3h24.2c0,0,0,0,0,0c0.7,0,1.3-0.6,1.3-1.3c0-0.1,0-0.2,0-0.2
            C49.3,61.2,45.8,56.6,41,54.7z M30.2,48c0-3.2,2.6-5.8,5.8-5.8s5.8,2.6,5.8,5.8c0,3.2-2.6,5.8-5.8,5.8C32.8,53.8,30.2,51.2,30.2,48
            z M25.3,65.5c0.7-5.1,5.2-9.1,10.7-9.1s10.1,4,10.7,9.1H25.3z"/>
        </g>
      </svg>
      <span class="tw-ml-4 opacity-54 tw-font-sans tw-text-xs">MENTORSHIP GROUP</span>
    </div>

    <!-- title -->
    <p class="tw-mb-4 font-title tw-text-xl">{{mentorshipGroup.group_name}}</p>
    <p class="tw-mb-1 tw-text-sm tw-bg-gray-200 tw-py-1 tw-px-1 tw-rounded-10 tw-inline-block">Goal: {{mentorshipGroup?.goal?.goal || ''}}</p>
    <p class="tw-mb-1 tw-text-sm">
      {{mentors.length}} {{'Mentor' | pluralize(mentors.length, 'Mentors')}},
      {{mentees.length}} {{'Mentee' | pluralize(mentees.length, 'Mentees')}}
    </p>

    <!-- actions -->
    <div class="tw-mb-5">
      <BaseButton
        :to="{ name: 'mentorshipGroupChat', params: { id: mentorshipGroup.id }}"
        icon
        icon-right
        text="CHAT"
        class="tw-mt-4 tw-mr-2 tw-inline-block tw-border-0 tw-bg-gray-300"
      >
        <svg class="my-btn__icon" slot="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
          <path d="M7.2,72c-0.8,0-1.7-0.2-2.3-0.8c-1-0.8-1.5-2.1-1.3-3.4l2.1-13.2c-3.6-5.9-5.4-12.6-5.4-19.3c0-16.5,11.9-31,28.1-34.6l0,0
            c10.9-2.3,22,0.4,30.6,7.5c8.4,7.1,13.2,17.6,12.8,28.7C71.1,52.9,59.3,67,43.6,70.3c-7.7,1.7-15.7,0.8-23-2.7L8.4,71.8
            C8,72,7.6,72,7.2,72z M29.2,4.8C14.9,7.9,4.5,20.7,4.5,35.4c0,6.3,1.7,12.4,5.2,17.6c0.2,0.4,0.4,1,0.4,1.5L8,67.6l12.1-4.2
            c0.6-0.2,1-0.2,1.7,0.2c6.5,3.4,13.8,4.2,20.9,2.7c13.6-3.1,24.1-15.5,24.5-29.5C67.7,27,63.5,17.8,56,11.5
            C48.7,5.2,38.8,2.9,29.2,4.8L29.2,4.8z M28.8,2.9L28.8,2.9L28.8,2.9z"/>
        </svg>
      </BaseButton>

      <BaseButton
        :to="{ name: 'mentorshipGroupTasks', params: { id: mentorshipGroup.id }}"
        icon
        icon-right
        text="VIEW TASKS"
        class="tw-mt-4 tw-mx-2 tw-inline-block tw-border-0 tw-bg-gray-300"
      >
        <svg class="my-btn__icon" slot="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
          <g>
            <path d="M64.4,17.3c0-4.6-3.7-8.2-8.2-8.2h-4.2c-0.7-2.4-3-4.1-5.5-4.1h-3.3C41.5,1,37-1,33,0.6C31,1.4,29.3,3,28.6,5h-3.2
              c-2.5,0-4.8,1.7-5.5,4.1h-4.2c-4.6,0-8.2,3.7-8.2,8.2v46.5c0,4.6,3.7,8.2,8.2,8.2h40.7c4.6,0,8.2-3.7,8.2-8.2V17.3H64.4z
              M22.9,10.7c0-1.4,1.1-2.5,2.5-2.5h4.4c0.8,0,1.5-0.5,1.6-1.3c0.5-2.5,2.9-4.1,5.3-3.6c1.8,0.4,3.2,1.8,3.6,3.6
              c0.2,0.8,0.9,1.3,1.6,1.3h4.5c1.4,0,2.5,1.1,2.5,2.5v1.2c0,1.4-1.1,2.5-2.5,2.5H25.2c-1.4,0-2.5-1.1-2.5-2.5L22.9,10.7z M61.1,63.8
              c0,2.7-2.2,4.9-4.9,4.9H15.5c-2.7,0-4.9-2.2-4.9-4.9V17.3c0-2.7,2.2-4.9,4.9-4.9h4c0.2,3,2.7,5.4,5.8,5.4h21c3,0,5.6-2.3,5.8-5.4h4
              c2.7,0,4.9,2.2,4.9,4.9V63.8z"/>
            <path d="M26.7,28h-7.8c-0.9,0-1.6,0.7-1.6,1.6v7.8c0,0.9,0.7,1.6,1.6,1.6h7.8c0.9,0,1.6-0.7,1.6-1.6v-7.8
              C28.4,28.7,27.6,28,26.7,28z M25.1,35.8h-4.5v-4.5h4.5V35.8z"/>
            <path d="M51.9,31.8H36c-0.9,0-1.6,0.7-1.6,1.6S35.1,35,36,35h15.9c0.9,0,1.6-0.7,1.6-1.6S52.8,31.8,51.9,31.8z"/>
            <path d="M26.7,46.9h-7.8c-0.9,0-1.6,0.7-1.6,1.6l0,0v7.8c0,0.9,0.7,1.6,1.6,1.6h7.8c0.9,0,1.6-0.7,1.6-1.6v-7.8
              C28.4,47.8,27.6,46.9,26.7,46.9L26.7,46.9z M25.1,54.9h-4.5v-4.5h4.5V54.9z"/>
            <path d="M51.9,50.9H36c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6h15.9c0.9,0,1.6-0.7,1.6-1.6C53.5,51.6,52.8,50.9,51.9,50.9z"
              />
          </g>
        </svg>
      </BaseButton>
    </div>

    <div class="tw-border-b tw-w-full"></div>
  </div>
</template>

<script>
export default {
  name: 'MentorshipGroup',
  props: {
    mentorshipGroup: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      participants: [],
    };
  },
  computed: {
    mentors() {
      return this.participants.filter((p) => Number(p.type) === 3)
    },
    mentees() {
      return this.participants.filter((p) => Number(p.type) === 4)
    },
  },
  methods: {
    async getGroupParticipants() {
      const participants = await this.$store.dispatch('getMentorshipGroupParticipants', this.mentorshipGroup.id);

      if (Array.isArray(participants)) {
        this.participants = participants;
      }
    },
  },
  async created() {
    this.getGroupParticipants();
  },
};
</script>

<style>

</style>
