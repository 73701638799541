<template>
  <!-- NOTE: DON'T USE AppView COMPONENT, UNLESS <portal> -->
  <transition name="fade" mode="out-in">
    <div v-if="error">
      <p class="opacity-54 tw-text-xs tw-py-12 tw-block tw-text-center" v-html="error"></p>
    </div>
    <div v-else-if="loading">
      <p class="opacity-54 tw-text-xs tw-py-12 tw-block tw-text-center">Loading...</p>
    </div>
    <div v-else class="tw-mt-8 px-body lg-grid lg:tw-flex box-border">
      <div class="lg-grid__col-2/3">
        <div class="tw-text-center tw-max-w-lg tw-mx-auto" v-if="user">
          <UserPhoto
            :photo="user.photo"
            :user-name="user"
            photo-size="tw-w-20 tw-h-20 tw-inline-block"
          />
          <h4 class="font-title tw-text-xl leading-24 tw-mt-3">{{ user.name }} {{ user.last_name }}</h4>
          <p class="tw-text-sm tw-text-center opacity-54 tw-text-black leading-19 tw-pt-2">
            Profession: <span class="tw-font-bold">{{ user.profession.profession }}</span>
          </p>
          <template v-if="user.department">
            <p class="tw-text-sm tw-text-center opacity-54 tw-text-black tw-mt-2 leading-19 tw-px-2 tw-inline-block">
              Department: <b>{{ user.department?.departments }}</b>
            </p>
          </template>
          <template v-if="user.regional_area">
            <p class="tw-text-sm tw-text-center opacity-54 tw-text-black tw-mt-2 leading-19 tw-px-2 tw-inline-block">
              Region: <b>{{ user.regional_area?.region }}</b>
            </p>
          </template>
          <template v-if="user.sub_area">
            <p class="tw-text-sm tw-text-center opacity-54 tw-text-black tw-mt-2 leading-19 tw-px-2 tw-inline-block">
              Sub-area: <b>{{ user.sub_area?.sub_area }}</b>
            </p>
          </template>
          <p class="tw-text-sm tw-text-center opacity-54 tw-text-black leading-19 tw-pt-2 tw-mb-1">
            availability
          </p>
          <p
            class="tw-text-xs tw-mr-2 tw-text-center opacity-54 tw-text-white leading-19 tw-bg-gray-700 tw-rounded-full tw-px-2 tw-py-1 tw-inline-block tw-mb-1"
            v-for="(availability, index) in user.availabilities"
            :key="index"
          >
            {{ availability.availability }}
          </p>
          <p class="tw-text-base leading-19 opacity-54 tw-mt-6">{{ user.bio }}</p>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

export default {
  props: ['id'],
  data() {
    return {
      loading: true,
      error: undefined,
      user: {},
    };
  },
  async created() {
    this.$store.dispatch('getUserData', this.id)
      .catch((err) => {
        const errorMessage = 'Error in preparing your profile';
        console.warn(errorMessage, err);
        this.$toasted.global.appError({ errorMessage });
      })
      .then((user) => {
        this.user = Object.assign({}, user || {});

        this.loading = false;
      });
  },
};
</script>

<style>

</style>
