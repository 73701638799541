export default {
  computed: {
    appLogoUrl() {
      const logoUrl = this.$store.state.settings.logo;

      if (/(resource\/)/.test(logoUrl)) {
        return this.userPhoto(logoUrl);
      }
      return logoUrl;
    },
    appBgImage() {
      const bgImage = this.$store.state.settings.landingpage?.bgImage || '';

      if (/(resource\/)/.test(bgImage)) {
        return this.userPhoto(bgImage);
      }
      return bgImage;
    },
    welcomeMessage() {
      return this.$store.state.settings.welcomeMessage;
    },
  },
};
