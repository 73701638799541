import resolve from "../modules/api/resolve";
import { apiPost, generateFormData } from "../modules/apiHelper";
import { toBoolean } from "../modules/dataHelper";
import _sortBy from 'lodash/sortBy';

export default {
  actions: {
    async getCompanyExperts(context) {
      const formData = generateFormData({
        companyid: context.rootState.User.companyid,
      });

      const result = await resolve(apiPost('active_experts_log', formData, 61)).then(async (data) => {
        if (!data) return false;

        return data.active_experts_log;
      });

      return result;
    },
    async getExpert(context, expertid) {
      const formData = generateFormData({
        expertid
      });

      const result = await resolve(apiPost('expert_d', formData, 62)).then(async (data) => {
        if (!data) return false;

        return data.expert_d[0];
      });

      return result;
    },
    async getExpertByLinkid(context, linkid) {
      const formData = generateFormData({
        linkid,
      });

      const result = await resolve(apiPost('linkid', formData, 62)).then(async (data) => {
        if (!data) return false;

        return data.linkid[0];
      });

      return result;
    },
    async createExpertQuestion(context, data) {
      const formData = generateFormData({
        ...data,
        companyid: context.rootState.User.companyid,
        userid: context.rootState.User.id,
      });

      const response = await apiPost('create_expt_question', formData, 62)
        .catch((err) => {
          console.warn('err::createExpertQuestion::create_expt_question');
          return false
        })
        .then((res) => {
          if (!res) return false;
          if (toBoolean(res.data.error)) return false;

          return true;
        });
      //

      return response;
    },
    async getMyExpertQuestions(context, expertid) {
      const formData = generateFormData({
        userid: context.rootState.User.id,
        expertid,
      })

      // get awaiting approval
      const awaitingApprovalQuestionsProm = resolve(apiPost('un_user_question_log', formData, 62))
      // get approved
      const approvedQuestionsProm = resolve(apiPost('user_question_log', formData, 62))
      // get unapproved
      const declinedQuestionsProm = resolve(apiPost('my_decl_app_question_log', formData, 62))

      return Promise.all([
        awaitingApprovalQuestionsProm,
        approvedQuestionsProm,
        declinedQuestionsProm,
      ]).then(([
        awaitingApprovalQuestionsRes,
        approvedQuestionsRes,
        declinedQuestionsRes,
      ]) => {
        const allMenteeExpertQuestions = []

        if (awaitingApprovalQuestionsRes) {
          allMenteeExpertQuestions.push(...(awaitingApprovalQuestionsRes.un_user_question_log || []));
        }

        if (approvedQuestionsRes) {
          allMenteeExpertQuestions.push(...(approvedQuestionsRes.user_question_log || []));
        }

        if (declinedQuestionsRes) {
          allMenteeExpertQuestions.push(...(declinedQuestionsRes.my_decl_app_question_log || []));
        }

        // sort by date of post
        return _sortBy(allMenteeExpertQuestions, 'dateofPost');
      });
    },
    async getExpertApprovedQuestionsAndAnswers(context, data) {
      const formData = generateFormData(data);

      const questions = await resolve(apiPost('experts_log_questions', formData, 62)).then(async (res) => {
        if (!res) return false;

        return res.experts_log_questions;
      });

      const answers = await resolve(apiPost('experts_answer_log', formData, 62)).then(async (res) => {
        if (!res) return false;

        return res.experts_answer_log;
      });

      if (!questions || !answers) {
        return questions;
      }

      const mappedQuestionsWithAnswers = questions.map((question) => {
        const questionAnswers = answers.filter((answer) => answer.questionid == question.id);

        return {
          ...question,
          answers: questionAnswers,
        }
      });

      return _sortBy(mappedQuestionsWithAnswers, (question) => !!question.answers.length);
    },
    async getCompanyQuestionInstructions(context) {
      const formData = generateFormData({
        companyid: context.rootState.User.companyid,
      });

      const result = await resolve(apiPost('guide_log', formData, 62)).then(async (response) => {
      if (!response) return false;

      if (response.guide_log.length) {
        return response.guide_log[response.guide_log.length - 1]?.guide;
      }

      // return empty string
      return '';
    });

    return result;
    },
    async getMyExpertAnswers(context, data) {
      const formData = generateFormData({
        ...data,
        companyid: context.rootState.User.companyid,
        userid: context.rootState.User.id,
      });

      const result = await resolve(apiPost('experts_answer_log', formData, 62)).then(async (data) => {
        if (!data) return false;

        return data.experts_answer_log;
      });

      return result;
    },
    async saveExpertAnswer(context, data) {
      const formData = generateFormData(data);

      const response = await apiPost('create_expt_a', formData, 62)
        .catch((err) => {
          console.warn('err::saveExpertAnswer::create_expt_a');
          return false
        })
        .then((res) => {
          if (!res) return false;
          if (toBoolean(res.data.error)) return false;

          return true;
        });
      //

      return response;
    }
  },
};
