<template>
  <div class="mx-body tw-mt-8 faq">
    <p><strong>1.&nbsp;</strong> <strong>What is eMentored Platform?</strong></p>
    <p>eMENTORED is an online mentorship platform that matches mentors and mentees to connect, pass knowledge and share experience on a secure space, while monitoring the impact and progress of the journey.</p>
    <p><strong>2.&nbsp;</strong> <strong>How does the platform work?</strong></p>
    <p>eMentored is accessible online via the URL -&nbsp;<a href="https://fellowship.ementored.com">https://fellowship.ementored.com</a> . Mentors and mentees on the platform can chat, share resources and track progress via the platforms features.
    </p>
    <p><strong>3.&nbsp;</strong> <strong>Mentee</strong></p>
    <p>As a mentee, you will be mentored based on the goals you selected on&nbsp;Signup when you log in. You are required to request to be mentored by a mentor of your choice by clicking the&nbsp;<strong>Find A Mentor</strong>&nbsp;button. After the
      Mentor accepts your request, you will communicate with each other (Break the ice) via a chat or video call on the platform, and the Mentor will create&nbsp;Tasks&nbsp;for you to complete and share&nbsp;Resources&nbsp;to guide you in completing
      the tasks. When you complete all the tasks in the goal, the Mentor will end the match and you are required to complete a survey to provide feedback on the match.</p>
    <p><strong>4.&nbsp;</strong> <strong>How do I sign up on eMentored?</strong></p>
    <p>As a Mentee/Fellow in the eMentoring Africa Fellowship Program, the eMentored Administrator will automatically Sign you up. You will then get a verification email where you are required to VERIFY. Then, set up your password after which you
      will be re-directed to the Log In page.</p>
    <p>Log in and complete your PROFILE (add photo, choose goals, sign the Code of Conduct).</p>
    <p>&nbsp;</p>
    <p><strong>GETTING STARTED</strong></p>
    <p><strong>5.&nbsp;</strong> <strong>How do I create a profile on the platform?</strong></p>
    <p>After logging in, you will be required to create your profile. You can do this by following the Step-by-Step process as outlined below:</p>
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Write your&nbsp;BIO&nbsp;and upload a good&nbsp;Profile Photo&nbsp;of yourself.</p>
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Choose the&nbsp;PILLARS&nbsp;&amp;&nbsp;GOALS&nbsp;you would like to be mentored on.</p>
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Note&nbsp; Do not forget to read and sign the&nbsp;Code of Conduct&nbsp;before starting the mentorship.</p>
    <p>&nbsp;</p>
    <p><strong>6.&nbsp;</strong> <strong>How do I find a mentor on the platform?</strong></p>
    <p>To find a mentor, click on&nbsp;<strong>FIND A MENTOR</strong>&nbsp;in the left menu bar where a list of mentors in the pillars and goals you selected will be displayed.&nbsp;<strong>SEND REQUEST</strong>&nbsp;to specific mentor(s) to request
      a match. You will receive an email notification once the selected mentor has Accepted the match request.</p>
    <p><i>Note&nbsp; mentor and mentee matches are only formed between mentors and mentees within the same pillars and goals.</i></p>
    <p><strong>7.&nbsp;</strong> <strong>How do I communicate with my mentor?</strong></p>
    <p>To communicate with your mentor, go to&nbsp;My Mentors&nbsp;tab and click on one of your mentors. A conversation log will appear and you can chat easily. You can also set up a video call meeting within the conversation log.</p>
    <p><strong>8.&nbsp;</strong> <strong>Video Calling feature</strong></p>
    <p>This is a video calling functionality that enables a Mentor and Mentee to communicate effectively virtually. It can also be used by mentees in a Group chat or Mentors in a Group chat to apply in Group Mentorship.</p>
    <p><strong>9.&nbsp;</strong> <strong>Group Chat</strong></p>
    <p>All Mentees can chat among themselves via the&nbsp;GROUP CHAT&nbsp;tab on the left menu bar.</p>
    <p><strong>10.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong> <strong>Where can I access my Resources?</strong></p>
    <p>Under the&nbsp;Resource&nbsp;tab, you can access resources shared by your mentor or admin to use as a guide in your mentorship journey. Resources are goal-specific and provide detailed information about the goal.</p>
    <p><strong>11.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong> <strong>How do I upload resources for my mentor to review?</strong></p>
    <p>To upload resources, go to the&nbsp;Resources&nbsp;tab and click&nbsp;Upload. You can upload documents in PDF or MS Office Word format and links.</p>
    <p><strong>12.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong> <strong>How do I access My Tasks?</strong></p>
    <p>Go to&nbsp;MY TASKS&nbsp;on the left menu tab to view the current and upcoming tasks created for you by mentors in specific pillars/goals.</p>
    <p>Your Mentor(s) will create Tasks for you to complete under a specific goal, which will include the Task Description and a Timeline.</p>
    <p>To&nbsp;Submit a Task Report, click on the specific task to view it in full. You can either&nbsp;WRITE&nbsp;in your task report or&nbsp;Upload&nbsp;a&nbsp;resource&nbsp;and click&nbsp;SUBMIT. Once completed, click on&nbsp;MARK
      COMPLETED&nbsp;and the status of the task will be displayed. Your Mentor will proceed to review the task. You will receive a Notification once your Mentor sends feedback. Your task can either be&nbsp;Accepted&nbsp;or&nbsp;Reverted&nbsp;by the
      mentor. When Reverted, ensure to revise the task and consult your mentor for guidance on the same.</p>
    <p><strong>&nbsp;</strong></p>
    <p><strong>ACCOUNT AND PROFILE MANAGEMENT</strong></p>
    <p><strong>13.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong> <strong>How do I manage my account information on the platform?</strong></p>
    <p>You can review your personal details and edit them when necessary.</p>
    <p><strong>14.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong> <strong>How do I change my password on the platform?</strong></p>
    <p>To change your password, click on&nbsp;Forgot Password&nbsp;on the Log In page. A link will be sent to your email for your to reset your password and log back in.</p>
    <p>Note&nbsp; Do not share your password with anyone to avoid compromising your account.</p>
    <p><strong>TECHNICAL ISSUES</strong></p>
    <p><strong>15.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong> <strong>What do I do if I can't log in to eMentored?</strong></p>
    <p>If you have trouble logging into the platform, try resetting your password via the&nbsp;Forgot Password&nbsp;button on the login page.</p>
    <p>If the issue persists, contact&nbsp;Support&nbsp;via&nbsp;Jivo Chat&nbsp;on the screen. An admin will assist in less than 24hrs.</p>
    <p><strong>16.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong> <strong>What do I do if I'm having trouble accessing the platform?</strong></p>
    <p>Contact&nbsp;Support&nbsp;via&nbsp;Jivo Chat&nbsp;on the screen to report the issue and you will receive assistance.</p>
    <p><strong>17.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong> <strong>What do I do if I can't upload files on the platform?</strong></p>
    <p>First, ensure you are uploading the correct&nbsp;formats&nbsp;recommended i.e., PDF, Word doc, or Link.</p>
    <p>If the issue has nothing to do with the format of the files, try refreshing your page and attempt to upload files again.</p>
    <p>Still nothing? Contact support via&nbsp;ELLY&nbsp;or EBBY</p>
    <p><strong>&nbsp;</strong></p>
    <p><strong>CODE OF CONDUCT</strong></p>
    <p><strong>18.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong> <strong>What is the Code of Conduct on eMentored?</strong></p>
    <p>The Code of Conduct outlines the rules and regulations that a mentor should follow during the mentorship.</p>
    <p><strong>19.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong> <strong>What behaviour is expected of mentors and mentees on the platform?</strong></p>
    <p>Mentees are required to be professional in their communication with mentors and to undertake all mentorship sessions in a respectful and sober manner that is free of all forms of discrimination and bias.</p>
    <p>Mentees are also advised to create a conducive and friendly environment and be open to instruction and guidance by their mentor(s) to achieve the goals of the mentorship efficiently.</p>
    <p>Ensure to carefully read the&nbsp;Code of Conduct&nbsp;form in the platform and sign it before proceeding with the mentorship.</p>
    <p><strong>20.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong> <strong>How do I report issues or concerns on the eMentored platform?</strong></p>
    <p>To report issues and concerns regarding the mentor, contact&nbsp;Support&nbsp;under&nbsp;EBBY&nbsp;or&nbsp;ELLY&nbsp;or approach the eMentored administrator for help.</p>
    <p><strong>21.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong> <strong>How do I give feedback on the&nbsp;eMentored Platform?</strong></p>
    <p>To give any feedback, contact&nbsp;Support.</p>
    <p><strong>22.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong> <strong>How do I end a mentoring relationship on the platform?</strong></p>
    <p>If you would like to&nbsp;End a Match, contact&nbsp;Support&nbsp;with your request and justification.</p>
    <p>&nbsp;</p>
    <p>Thank you for using eMentored.</p>
    <p>If you have any additional questions or concerns, please reach out to our support team at&nbsp;<a href="mailto:mentor@ementoringafrica.or.ke">mentor@ementoringafrica.or.ke</a>.</p>
  </div>
</template>

<script>
  export default {
    name: 'FAQMenteePartial',
  };

</script>

<style lang="scss" scoped>
  .faq {

    * {
      font-size: 20px;
    }

    h2,
    h1 {
      font-size: 50px !important;
    }

    p {
      margin-bottom: 20px;
    }

    ol {
      line-height: 1.5;
      list-style-type: decimal;
      color: var(--primary-orange);
    }

    ul {
      list-style-type: disc;
      padding-left: 40px;
    }

    a {
      color: var(--primary-blue);
    }
  }

</style>
