<template>
  <AppView>
    <TopNav slot="top-app-view" :class="{ 'tw-shadow-app' : isMobileView }"
      :title="`${user.name || ''} ${user.last_name || ''}`" center-title
      title-class="lg:ml-body tw-tracking-wide font-title" type="back" :prev-route="prevRoute" :show-back-button="true"
      :show-user-photo="false" @input="openSideNav()">
      <UserPhoto v-if="user.pic" :photo="user.pic" photo-size="tw-h-6 tw-w-6" slot="title-left"
        class="tw-inline-block tw-mx-2" />
    </TopNav>

    <div class="tw-mt-8 px-body lg-grid lg:tw-flex box-border">
      <div class="lg-grid__col-2/3 lg:tw-pr-8 tw-relative">
        <transition-group mode="out-in" name="fade" appear tag="div">
          <div v-if="error" key="error" class="tw-text-center">
            <p class="tw-text-red-500 tw-mb-4">
              Error trying to to get expert
            </p>
            <BaseButton text="Back to Mentors & Experts" :to="{ name: 'menteeMentorsView' }" />
          </div>

          <ContentLoadingProfile v-else-if="loading" key="loading" />

          <div v-else key="body">
            <!-- * Expert Bio -->
            <div class="tw-w-full tw-flex tw-flex-wrap lg:tw-flex-nowrap tw-group tw-mb-4">
              <div class="tw-min-w-32 tw-mr-6 tw-my-1">
                <UserPhoto :photo="user.pic" :user-name="{
                    name: user.name,
                  }" photo-size="tw-h-32 tw-w-32" :class="[
                    'tw-rounded-10--force --force-children tw-bg-app-white-87',
                  ]" />
              </div>

              <div class="tw-pr-2 tw-flex-shrink">
                <div class="tw-flex tw-items-center">
                  <p class="tw-text-xl tw-font-bold tw-mb-1">{{user.name}} {{user.last_name}}</p>
                </div>
                <p class="tw-text-sm" v-html="user.bio"></p>
              </div>
            </div>

            <div v-if="expertQuestionCornerStatus === QUESTION_CORNER_OFF"
              class="tw-z-0 tw-p-5 tw-bg-gray-300 tw-rounded-32">
              <p>Questions are disabled for now</p>
            </div>
            <div v-if="expertQuestionCornerStatus === QUESTION_CORNER_ON"
              class="tw-z-0 tw-p-5 tw-text-center tw-mb-3 tw-bg-black tw-bg-opacity-5 tw-rounded-10">
              <p>Questions corner</p>
            </div>

            <!-- * Your Questions Section -->
            <div class="tw-flex tw-flex-col">
              <div class="tw-text-center tw-relative tw-mb-3">
                <div
                  class="tw-rounded-32 tw-p-4 tw-bg-black tw-bg-opacity-5 tw-inline-block tw-shadow-sm sm:tw-max-w-80">
                  <p v-html="instructions" class="tw-text-xs"></p>
                  <div class="tw-mt-2 tw-opacity-25">
                    <p v-if="true" class="tw-uppercase-- tw-text-xs">Admin</p>
                  </div>
                </div>
              </div>

              <!-- * QUESTIONS AND REPLIES -->
              <template v-for="questionOrReply in questionsAndReplies">
                <div :key="`${questionOrReply.question?.length ? 'q' : 'r'}-${questionOrReply.id}`" :class="[
                    {
                      'tw-text-right': questionOrReply.question,
                      'tw-text-left': questionOrReply.answer,
                    },
                    'tw-relative tw-mb-3'
                  ]">
                  
                  <div v-if="questionOrReply.question"
                    class="tw-rounded-32 tw-p-4 tw-bg-white tw-inline-block tw-shadow-sm sm:tw-max-w-80">
                    <div>
                      <p class="tw-text-sm tw-font-sans">{{ questionOrReply.question }}</p>
                    </div>
                    <div>
                      <div class="tw-mt-2 tw-opacity-25">
                        <p v-if="Number(questionOrReply.app) === QUESTION_STATUS_PENDING_APPROVAL"
                          class="tw-uppercase-- tw-text-xs">Pending approval</p>
                        <p v-if="Number(questionOrReply.app) === QUESTION_STATUS_APPROVED"
                          class="tw-uppercase-- tw-text-xs tw-text-blue-500">Approved</p>
                        <p v-if="Number(questionOrReply.app) === QUESTION_STATUS_DECLINED"
                          class="tw-uppercase-- tw-text-xs tw-text-red-500">Declined</p>
                      </div>
                    </div>
                  </div>

                  <div v-if="questionOrReply.answer"
                    class="tw-rounded-32 tw-p-4 tw-bg-app-primary-blue tw-text-white tw-inline-block tw-shadow-sm sm:tw-max-w-80">
                    <div>
                      <div class="tw-text-sm tw-font-sans" v-html="questionOrReply.answer"></div>
                    </div>
                  </div>
                </div>
              </template>
            </div>

            <div style="padding-bottom: 150px;"></div>

            <portal to="bottom-app-view" slim>
              <div class="lg-grid">
                <div :class="[
                    'tw-flex-grow-0 tw-flex-shrink-0 tw-w-full lg-grid__col-2/3',
                  ]" ref="chat-bottom-bar">
                  <div v-if="expertQuestionCornerStatus === QUESTION_CORNER_OFF"
                    class="tw-mb-6 tw-flex tw-flex-nowrap tw-items-end transition-fast tw-p-4 tw-bg-gray-300 tw-rounded-32 mx-body lg:tw-mr-0">
                    Questions are disabled for now
                  </div>
                  <!-- // * chat action bar -->
                  <div v-else :class="[
                      'tw-mb-6 tw-flex tw-flex-nowrap tw-items-end transition-fast',
                      { 'mx-body lg:tw-mr-0': !focusMode },
                      { 'tw-mx-2 lg:tw-ml-8 lg:tw-mr-0': focusMode },
                    ]">
                    <div
                      class="tw-flex tw-flex-wrap tw-shadow-app tw-p-1 tw-bg-white tw-flex-1 tw-items-end tw-mr-4 tw-relative tw-rounded-[24px] tw-border-2 tw-border-app-primary-orange">
                      <!-- // * textarea -->
                      <textarea ref="text-input-el" rows="1"
                        class="leading-24 tw-p-2 tw-text-base tw-flex-1 tw-bg-transparent tw-w-full tw-resize-none transition-fast"
                        :style="'height: ' + textareaScrollHeight + '; max-height: 240px;'"
                        placeholder="Type your question" v-model="form.text" @focus.prevent.stop="focusMode = true"
                        @blur.prevent.stop="focusMode = false"></textarea>
                    </div>
                    <!-- // * send button -->
                    <template>
                      <BaseButton text="" icon style="line-height: 0; font-size: 0;"
                        class="tw-shadow-app disabled:tw-bg-black tw-bg-app-primary-orange tw-rounded-full tw-text-white tw-py-3 tw-border-0 focus:tw-bg-gray-600 transition-fast"
                        :disabled="disabled || sending" @click="sendMessage">
                        <svg slot="icon" class="tw-w-6 tw-h-6 tw-mx-3" version="1.1" xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 72 72"
                          style="enable-background:new 0 0 72 72;" xml:space="preserve">
                          <path d="M70.9,0.9c-0.8-0.8-2.2-1-3.3-0.6l-65.8,25c-1.2,0.6-2,1.8-1.8,3c0,1.2,0.8,2.2,2,2.6l28.8,10l10.4,29.1
                            c0.4,1.2,1.6,2,2.8,2l0,0c1.4,0,2.6-1,3-2.2L71.9,4.1C72.2,2.9,71.7,1.7,70.9,0.9z M44.1,66.1l-9.8-27c-0.4-0.8-1-1.6-1.8-1.8
                            L5.7,28.1L67.1,4.9L44.1,66.1z M39.6,32.3c-0.8-0.8-0.8-2,0-2.8l10-10c0.8-0.8,2-0.8,2.8,0s0.8,2,0,2.8l-10,10
                            c-0.4,0.4-1,0.6-1.4,0.6C40.7,33,40.1,32.5,39.6,32.3z" />
                        </svg>
                      </BaseButton>
                    </template>
                  </div>
                </div>
              </div>
            </portal>
          </div>
        </transition-group>
      </div>
    </div>
  </AppView>
</template>

<script>
import ContentLoadingProfile from '@/components/ContentLoadingProfile.vue';
import chatMixin from '@/mixins/chat';
import _sortBy from "lodash/sortBy"

export default {
  name: 'Expert',
  components: {
    ContentLoadingProfile,
  },
  mixins: [chatMixin],
  props: ['id', 'prevRoute'],
  data() {
    return {
      loading: true,
      error: undefined,
      user: {},
      userId: this.id || this.$route.params.id,
      questions: [],
      replies: [],
      questionsAndReplies: [],
      instructions: '',
      form: {
        text: '',
      },
      errors: {
        text: undefined,
      },
      QUESTION_CORNER_OFF: 0,
      QUESTION_CORNER_ON: 1,
      QUESTION_STATUS_PENDING_APPROVAL: 0,
      QUESTION_STATUS_APPROVED: 1,
      QUESTION_STATUS_DECLINED: 2,
      sending: false,
      disabled: false,
    };
  },
  computed: {
    expertQuestionCornerStatus() {
      return Number(this.user?.swtch);
    },
  },
  methods: {
    async sendMessage() {
      const data = {
        title: this.form.text,
        expertid: this.userId,
      };

      this.sending = true

      const response = await this.$store.dispatch('createExpertQuestion', data);

      if (response) {
        this.form.text = '';
        this.$toasted.success('Question sent to admin for review', { duration: 4000 });

        // fetch mentee questions
        await this.getMenteeQuestions().then(this.combineQuestionsAndReplies);

        // todo: scroll down
      }

      this.sending = false
    },
    async getMenteeQuestions() {
      const data = await this.$store.dispatch('getMyExpertQuestions', this.userId);

      if (Array.isArray(data)) {
        // there's need to sort by date or sth
        this.questions = data
      }
    },
    async getExpertAnswers() {
      const data = await this.$store.dispatch('getMyExpertAnswers', {
        expertid: this.userId,
      });

      if (Array.isArray(data)) {
        // there's need to sort by date or sth
        this.replies = data
      }
    },
    async getCompanyExpertInstructions() {
      const instruction = await this.$store.dispatch('getCompanyQuestionInstructions');

      if (instruction) {
        this.instructions = instruction
      }
    },

    async combineQuestionsAndReplies() {
      const combined = [
        ...this.questions,
        ...this.replies,
      ];

      this.questionsAndReplies = _sortBy(combined, (item) => new Date(item.dateofPost).getTime());
    }
  },
  async created() {
    this.disabled = true;
    this.loading = true;
    await this.$nextTick();

    return this.$store.dispatch('getExpert', this.userId)
      .catch((err) => {
        const errorMessage = 'Error in getting data. Please try again later.';
        console.error(errorMessage, err);
        this.$toasted.global.appError({ errorMessage });
        this.error = errorMessage;
      })
      .then(async (user) => {
        if (!user) {
          this.error = "Could not get Expert";
          return false;
        }

        this.user = user;

        // redirect if not activated
        if (Number(user.status) !== 0) {
          this.$router.push({
            name: "menteeMentorsView"
          });
        }

        await this.getCompanyExpertInstructions();

        await Promise.all([
          this.getMenteeQuestions(),
          this.getExpertAnswers()
        ]).then(this.combineQuestionsAndReplies);

        this.error = undefined;
        this.loading = false;
        this.disabled = false;
        return user;
      });
  },
}
</script>

<style>

</style>
