import _sortBy from 'lodash/sortBy';
import _debounce from 'lodash/debounce';
import { apiGet, apiPost, generateFormData } from '../modules/apiHelper';
import { toBoolean } from '../modules/dataHelper';

const INTERVAL_SLOW = 20000;
const INTERVAL_NORMAL = 10000;
const INTERVAL_FAST = 3000; // ? make this slower for none-chromium browsers
const INTERVAL_DEFAULT = INTERVAL_NORMAL;

export default {
  state: {
    groupChatMessages: [],
    fetchingGroupMessages: undefined,
    fetchingGroupChatInterval: INTERVAL_NORMAL, // INTERVAL_FAST for fastFetching
    fetchingGroupChatError: false,
    totalNewGroupChatMessages: 0,
  },
  getters: {
    groupChatMessages: state => _sortBy(state.groupChatMessages, 'tym_unix'),
    groupChatMessage: state => id => state.groupChatMessages.find((message) => message.id == id),
    // todo: I need a way to mark as read group messages
    // totalNewUserMessages: (state, getters, rootState) => (id) => {
    //   const messages = state.message || [];
    //   return messages.filter(t => !toBoolean(t.status) && Number(t.senderid) !== Number(rootState.User.id)).length;
    // },
    groupLastMessage: state => (id) => {
      const messages = _sortBy(state.groupChatMessages, 'tym_unix') || [];
      return messages[messages.length - 1];
    },
    isGroupChatFetching: state => !!state.fetchingGroupMessages || false,
    fetchingGroupChatError: state => !!state.fetchingGroupChatError,
  },
  mutations: {
    addGroupChatMessages(state, messages) {
      // console.log('MESSAGES...');
      state.groupChatMessages = [...messages];
      // console.log(state.messages);
    },
    addGroupChatMessage(state, [id, message]) {
      state.groupChatMessages.push(message);
    },
    updateFetchingGroupChats(state, isFetching) {
      state.fetchingGroupMessages = isFetching;
      state.fetchingGroupChatInterval = isFetching ? INTERVAL_NORMAL : INTERVAL_SLOW;
    },
    isOnGroupChat(state, userId) {
      state.fetchingGroupChatInterval = INTERVAL_FAST;
    },
    resetGroupChatFetchSpeed(state) {
      state.fetchingGroupChatInterval = INTERVAL_NORMAL;
    },
    updateFetchingGroupChatError(state, error) {
      state.fetchingGroupChatError = error;
    },
    updateTotalNewGroupChatMessages(state, total) {
      state.totalNewGroupChatMessages = total;
    },
  },
  actions: {
    async getGroupChatMessages(context) {
      // if (process.env.NODE_ENV === 'development') return false;
      await context.dispatch('fetchGroupChatMessages');

      if (context.getters.isGroupChatFetching) return true;
      // This is set only once when this action is dispatched
      context.commit('updateFetchingGroupChats', true);

      try {
        setTimeout(async function fetch() {
          // const n = now() + context.state.fetchingGroupChatIntervals;
          if (context.getters.isGroupChatFetching) {
            await context.dispatch('fetchGroupChatMessages')
              .catch((err) => {
                context.commit('updatefetchingGroupChatError', true);
                throw err;
              });

            setTimeout(fetch, (context.state.fetchingGroupChatInterval || INTERVAL_DEFAULT));
          }
        }, (context.state.fetchingGroupChatInterval || INTERVAL_DEFAULT));
      } catch (err) {
        console.error('ERROR IN FETCHING MESSAGES! (groupchat.js::fetchGroupChatMessages)\n\n', err);
        context.commit('updatefetchingGroupChatError', true);
      }
    },
    async fetchGroupChatMessages(context) {
      const isMentor = context.getters.userTypeName === 'mentor';
      
      const formData = generateFormData({
        companyid: context.rootState.User.companyid,
      });

      const api = isMentor
        ? apiPost('group_chat_log', formData, 25)
        : apiPost('group_chat_log', formData, 29);

      await api.then(async (res) => {
          if (!res) return false;
          await context.commit('addGroupChatMessages', res.data.group_chat_log);

          return true;
        });
    },
    // called when logging out
    stopFetchingGroupChatMessages(context) {
      context.commit('updateFetchingGroupChats', false);
    },
    async sendGroupChatMessage(context, {
      text, attachmentId = undefined, attachmentType = undefined, chatReplyId = undefined, resourceType = 3 // resource
    }) {
      const isMentor = context.getters.userTypeName === 'mentor';

      // send message
      const formData = generateFormData({
        text,
        companyid: context.rootState.User.companyid,
        senderid: context.rootState.User.id,
        userid: context.rootState.User.id,
      });

      if (attachmentType && attachmentId) {
        formData.set(attachmentType, attachmentId);
        formData.set('resource_type', resourceType);
      }
      
      if (chatReplyId) {
        formData.set('chatrefid', chatReplyId);
      }

      const api = isMentor
        ? apiPost('chat', formData, 24)
        : apiPost('chat', formData, 30);

      const response = await api.then((res) => {
          if (!res) return false;
          if (toBoolean(res.data.error)) return false;
          return true;
        });

      return !!response;
    },
    markGroupMessagesAsRead(context, linkid) {
      const formData = new FormData();
      formData.set('linkid', linkid);

      apiPost('chat_seen', formData, 4);
    },
  },
};
