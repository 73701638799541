<template>
  <AppView
    padd-bottom
  >
    <TopNav
      slot="top-app-view"
      title="Frequently Asked Questions For Mentees"
      title-class="lg:ml-body lg-app:tw-font-bold"
      type="menu"
      @input="openSideNav()"
    />

    <template v-if="loading">
      <p class="tw-text-center tw-my-12 tw-text-xs opacity-54 tw-italic">loading...</p>
    </template>
    <template v-else>
      <template v-if="isKengen">
        <FAQMentee />
      </template>
      <template v-else-if="isFellowship">
        <FellowshipFAQMentee />
      </template>
    </template>
  </AppView>
</template>

<script>
import FAQMentee from '@/views/_partials/FAQMentee.vue';
import FellowshipFAQMentee from '@/views/_partials/FellowshipFAQMentee.vue';

export default {
  name: 'FAQMenteeView',
  components: {
    FAQMentee,
    FellowshipFAQMentee,
  },
  data() {
    return {
      loading: true,
    }
  },
  async mounted() {
    this.loading = true;

    // // get scope id
    // await this.$store.dispatch('setScope');
    // await this.$store.dispatch('evaluateScope');
    await this.$nextTick();

    // this.scopeId = this.$store.getters.scopeId;

    await this.$nextTick();

    this.loading = false;
  },
};
</script>

<style>

</style>
