<template>
  <AppView
    body-class="tw-flex tw-flex-col tw-mx-auto"
    padd-bottom
    :show-bottom-bar="!loading"
  >
    <template v-slot:default="{ recalculate }">
      <transition name="fade">
        <!-- header -->
        <header
          :class="[
            'tw-text-center tw-mb-4 tw-pb-4 tw-rounded-b-55 sm:mx-body transition-slow',
            { 'my-bg-pattern' : currentStep === 0 }
          ]"
          v-if="userTypeName && user.name"
        >
          <img
            :src="appLogoUrl"
            :data-src="appLogoUrl"
            class="tw-inline-block tw-py-8 tw-w-3/5 sm:tw-w-1/6 tw-object-contain"
            height="150px"
            style="max-height: 150px"
          >
          <div class="md:tw-border-l tw-border-gray-300 md:tw-ml-6 md:tw-pl-6 tw-inline-block tw-text-left">
            <h2 class="tw-text-xl font-title tw-leading-none tw-tracking-widest tw-capitalize">Welcome {{ user.name }}</h2>
            <h2 class="tw-text-xs font-title tw-leading-none tw-tracking-wider opacity-31">{{ userTypeName }}</h2>
          </div>
        </header>
      </transition>

      <transition name="fade" mode="out-in" appear>
        <div
          v-if="loading"
          @load="recalculate()"
        >
          <p class="opacity-54 tw-text-center tw-text-gray-700 tw-py-48">Loading...</p>
        </div>

        <WelcomeMessage
          v-else-if="currentStep === 0"
          @load="recalculate()"
          class="tw-max-w-md tw-mx-auto tw-w-full tw-h-full p-body lg:tw-py-8 transition-slow"
        />

        <div
          v-else
          @load="recalculate()"
          class="tw-flex tw-flex-col tw-items-stretch tw-self-stretch tw-flex-grow tw-w-full tw-h-full"
        >
          <!-- step bar [1,2,3] -->
          <template>
            <div
              v-if="currentStep < 4"
              class="tw-flex tw-flex-nowrap tw-items-center transition-fast tw-mb-2 mx-body"
            >
              <div :class="[
                'tw-rounded-full tw-flex-grow-0 tw-flex-shrink-0 tw-text-app-light-blue transition-fast leading-24 tw-w-6 tw-h-6 tw-text-center tw-box-content',
                { 'tw-bg-app-deep-blue tw-text-xl tw-font-bold tw-mr-2 tw-p-2': currentStep === 1 },
                { 'tw-mr-6 tw-text-base leading-19 tw-p-1': currentStep !== 1 },
                { 'tw-bg-green-500': currentStep !== 1 && stepOneIsValid },
                { 'bg-black-54': currentStep === 2 && !stepOneIsValid },
                { 'bg-black-31': currentStep === 3 && !stepOneIsValid },
              ]">
                <h4>1</h4>
              </div>
              <div :class="[
                'divider tw-flex-shrink tw-border tw-border-app-deep-blue transition-fast',
                { 'tw-flex-grow': currentStep === 1 },
                { 'tw-hidden tw-w-0': currentStep !== 1 },
              ]"></div>
              <div :class="[
                'tw-rounded-full tw-flex-grow-0 tw-flex-shrink-0 tw-text-app-light-blue transition-fast leading-24 tw-w-6 tw-h-6 tw-text-center tw-box-content',
                { 'tw-bg-app-deep-blue tw-text-xl tw-font-bold tw-mr-4 tw-p-2': currentStep === 2 },
                { 'tw-mx-6 tw-text-base leading-19 tw-p-1': currentStep !== 2 },
                { 'tw-bg-green-500': currentStep !== 2 && stepTwoIsValid },
                { 'bg-black-54': currentStep !== 2 && !stepTwoIsValid },
              ]">
                <h4>2</h4>
              </div>
              <div
                :class="[
                  'divider tw-border-app-deep-blue tw-flex-shrink tw-border transition-fast',
                  { 'tw-flex-grow': currentStep === 2 },
                  { 'tw-hidden tw-w-0': currentStep !== 2 },
                ]"
                v-if="isMainApp"
              ></div>
              <div
                :class="[
                  'tw-rounded-full tw-flex-grow-0 tw-flex-shrink-0 tw-text-app-light-blue transition-fast leading-24 tw-w-6 tw-h-6 tw-text-center tw-box-content',
                  { 'tw-bg-app-deep-blue tw-text-xl tw-font-bold tw-ml-4 tw-p-2 tw-mx-auto': currentStep === 3 },
                  { 'tw-text-base leading-19 tw-p-1': currentStep !== 3 },
                  { 'bg-black-31': currentStep === 1 },
                  { 'bg-black-54 tw-ml-6': currentStep === 2 },
                ]"
                v-if="isMainApp"
              >
                <h4>3</h4>
              </div>
              <div class="tw-flex-grow tw-flex-shrink-0" v-if="currentStep === 3"></div>

            </div>

            <transition name="slide-right" mode="out-in" appear>
              <h3 class="tw-text-xl leading-24 tw-font-bold mx-body font-title tw-tracking-wider" :key="stepTitle">
                {{ stepTitle }}
              </h3>
            </transition>
          </template>

          <!-- components by step -->
          <div class="p-body tw-pb-12 lg:tw-py-8 tw-box-border tw-relative tw-flex tw-flex-col tw-items-stretch tw-self-stretch tw-flex-grow tw-w-full -tw-h-full">
            <transition name="slide-right" mode="out-in" appear>

              <NewUserProfile
                v-if="currentStep === 1"
                @update="updateUserInfo"
                :bio="form.bio"
                :photo="form.photo"
                ref="profile-settings"
                class="tw-relative tw-flex-grow transition-slow tw-w-full tw-h-full tw-box-border tw-max-w-md tw-mx-auto"
              />

              <div
                v-else-if="currentStep === 2"
                class="tw-relative tw-flex-grow transition-slow tw-w-full tw-h-full tw-box-border tw-mx-auto"
              >
                <GoalsSettingsMentor
                  v-if="isMentor"
                  v-model="form.goals"
                  ref="goal-settings"
                />

                <!-- // TODO: mentee select packages -->
                <LicensePackagesPartial
                  v-else-if="isMentee"
                  v-model="form.py"
                  @input-success="toNextStep()"
                  ref="license-packages"
                />

              </div>
              <div
                v-else-if="currentStep === 3"
                class="tw-relative tw-flex-grow transition-slow tw-w-full tw-h-full tw-box-border tw-mx-auto"
              >
                <!-- @input-success="toNextStep()" -->
                <MipClasses
                  v-if="isMentor"
                  v-model="form.course"
                  ref="mip-classes"
                  class="tw-relative tw-flex tw-flex-col tw-self-stretch tw-flex-grow transition-slow tw-max-md tw-mx-auto"
                />

                <GoalsSettingsMentee
                  v-else-if="isMentee"
                  v-model="form.goals"
                  ref="goal-settings"
                />
              </div>


              <div
                v-else
                class="tw-relative tw-flex-grow transition-slow tw-w-full tw-h-full tw-box-border tw-max-md tw-mx-auto tw-text-center"
              >
                <!-- GOODIES -->
                <p class="tw-text-center tw-text-app-deep-blue tw-py-12 tw-max-w-64 tw-mx-auto">
                  Profile Set! <br />
                  Go back to homepage to read and sign Code of conduct to unlock
                  the door and start the mentoring journey
                </p>
                <BaseButton
                  text="Back Home"
                  to="/app"
                  class="tw-bg-gray-200"
                />
              </div>

            </transition>
          </div>
        </div>
      </transition>
    </template>

    <!-- Next Button -->
    <div
      slot="bottom-app-view"
      class="mx-body tw-pb-8 tw-pt-4 tw-flex tw-justify-between tw-items-end tw-max-w-md md:tw-mx-auto"
      v-if="currentStep < 4"
    >
      <BaseButton
        @click="logout()"
        text="logout"
        class="tw-shrink-0 tw-py-3"
      />
      <BaseButton
        text="Back"
        class="tw-mr-3 tw-py-3"
        v-if="currentStep > 1"
        @click="currentStep = 1"
      />
      <transition name="fade" mode="out-in">

        <BaseButton
          v-if="currentStep < 3 || (currentStep === 3 && isMentee)"
          class="tw-shrink-0 tw-py-3 tw-bg-black tw-text-white"
          :text="btnText"
          :disabled="(stepsAreDisabled && currentStep > 0) || btnIsDisabled"
          icon
          icon-right
          @click="toNextStep();"
        >
          <svg version="1.1" slot="icon" class="my-btn__icon tw-w-6 tw-h-6" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
            <path d="M16,67.8c0-1.2,0.4-2,1.2-2.9l29-29l-29-29c-1.6-1.6-1.6-4.1,0-5.7c1.6-1.6,4.1-1.6,5.7,0l31.9,31.9c1.6,1.6,1.6,4.1,0,5.7
              L22.9,70.8c-1.6,1.6-4.1,1.6-5.7,0C16.4,69.9,16,69,16,67.8z"/>
          </svg>
        </BaseButton>
        <!-- <BaseButton
          v-if="currentStep === 3"
          class="tw-shrink-0 tw-py-3 tw-block"
          text="I agree"
          :disabled="(stepsAreDisabled && currentStep > 0) || btnIsDisabled"
          @click="form.termsAndConditions = true"
        /> -->
      </transition>
    </div>
  </AppView>
</template>

<script>
import { mapGetters } from 'vuex';
import userApp from '@/mixins/userApp';

import GoalsSettingsMentor from '@/views/_partials/GoalsSettings/MentorGoalsSettings.vue';
import GoalsSettingsMentee from '@/views/_partials/GoalsSettings/Mentee/PillarsGoals.vue';
import NewUserProfile from '@/views/_partials/NewUserProfile.vue';
import WelcomeMessage from '@/views/_partials/WelcomeMessage.vue';
import MipClasses from '@/views/_partials/MipClasses.vue';
import LicensePackagesPartial from '@/views/_partials/LicensePackages.vue';

/**
 * step 1 = photo & bio
 * step 2 = select goals
 */
export default {
  name: 'NewUser',
  components: {
    GoalsSettingsMentor,
    GoalsSettingsMentee,
    NewUserProfile,
    WelcomeMessage,
    MipClasses,
    LicensePackagesPartial,
  },
  mixins: [userApp],
  data() {
    return {
      currentStep: 0,
      user: {},
      btnText: 'Next',
      btnIsDisabled: false,
      loading: true,
      error: undefined,
      isMainApp: false,
      form: {
        photoFile: undefined,
        photo: undefined,
        bio: '',
        goals: [],
        course: false,
        py: false,
      },
    };
  },
  computed: {
    ...mapGetters([
      'userTypeName',
    ]),
    stepOneIsValid() {
      const stepOneIsSorted = (this.user?.photo || '').length && (this.user?.bio || '').length;
      const isValid = this.form.bio.length > 0;
      // const isValid = !!(this.form.photoFile || this.form.photo) && this.form.bio.length > 0;

      if (stepOneIsSorted) {
        return true;
      }


      if (this.$refs['profile-settings']) {
        return isValid && this.$refs['profile-settings'].canSaveBio;
      }
      return isValid;
    },
    stepTwoIsValid() {
      if (this.isMentee && !this.isMainApp) {
        return true;
      }

      if (this.isMentee && this.isMainApp) {
        return !!Number(this.form.py);
      }

      if (this.isMentor) {
        return this.form.goals.length > 0;
      }

      return false;
    },
    stepThreeIsValid() {
      if (this.isMentor && !this.isMainApp) {
        return true;
      }

      if (this.isMentor && this.isMainApp) {
        // has done classes?
        return this.form.course;
      }

      if (this.isMentee) {
        return this.form.goals.length > 0;
      }

      return false;
    },
    stepTitle() {
      switch (this.currentStep) {
        case 1:
          return 'Complete your bio';
        case 2:
          return this.isMentor ? 'Areas of interest' : 'Select a package';
        case 3:
          return this.isMentor ? 'Mentors in Practise Sessions' : 'Set Goals';
        default:
          return '';
      }
    },
    stepsAreDisabled() {
      return (!this.stepOneIsValid && this.currentStep === 1)
      || (!this.stepTwoIsValid && this.currentStep === 2)
      || (!this.stepThreeIsValid && this.currentStep === 3);
    },
  },
  methods: {
    async toNextStep() {
      let canDoNext = false;
      // save current step's data, then go to next
      switch (this.currentStep) {
        case 0:
          canDoNext = true;
          break;
        case 1:
          canDoNext = await this.saveStepOne();
          break;
        case 2:
          canDoNext = this.isMentor
            ? await this.saveMentorStepTwo()
            : await this.saveMenteeStepTwo();
          break;
        case 3:
          canDoNext = this.isMentor
            ? await this.saveMentorStepThree()
            : await this.saveMenteeStepThree();
          break;
        default:
          this.currentStep = 1; // idk why this
      }

      if (canDoNext) {
        this.currentStep += 1;
        await this.validateSteps();
      }
    },
    async saveStepOne() {
      if (!this.stepOneIsValid) return false;

      this.btnText = 'Please wait...';
      this.btnIsDisabled = true;

      const photoPromise = this.form.photoFile ? 
        this.$store.dispatch('updateUserPhoto', [this.form.photoFile, (this.form.photoFile instanceof File ? false: true)])
        : true;

      const response = await Promise.all([
        photoPromise,
        this.$store.dispatch('updateUserBio', this.form.bio),
      ]).then(([res1, res2]) => res1 && res2);

      const user = await this.$store.dispatch('getUserData', this.$store.getters.userId);

      this.user = Object.assign({}, user);
      this.form.photo = this.user.photo;

      this.btnIsDisabled = false;
      this.btnText = 'Next';

      return response;
    },
    async saveMentorStepTwo() {
      if (!this.stepTwoIsValid) return false;
      if (!this.$refs['goal-settings']) {
        this.$toasted.global.appError();
        return false;
      }

      this.btnText = 'Please wait...';
      this.btnIsDisabled = true;

      // * calling a child component's method, yeah
      await this.$refs['goal-settings'].saveSettings();

      this.btnIsDisabled = false;
      this.btnText = 'Next';

      return true;
    },
    async saveMenteeStepTwo() {
      if (!this.stepTwoIsValid) {
        // TODO, check if selected package is paid just to confirm
        // run getMenteePackage()
        return false;
      }

      return true;
    },
    async saveMentorStepThree() {
      return true;
    },
    async saveMenteeStepThree() {
      if (!this.stepThreeIsValid) return false;
      if (!this.$refs['goal-settings']) {
        this.$toasted.global.appError();
        return false;
      }

      this.btnText = 'Please wait...';
      this.btnIsDisabled = true;

      // * calling a child component's method, yeah
      await this.$refs['goal-settings'].saveSettings();

      this.btnIsDisabled = false;
      this.btnText = 'Next';

      return true;
    },
    async updateUserInfo(photoFile, bio) {
      this.form.photoFile = photoFile;
      this.form.bio = bio;
    },
    async validateSteps() {
      if (!this.isMainApp) {
        this.currentStep = 1;
      }

      if (this.stepOneIsValid) {
        this.currentStep = 2;
      }

      if (this.stepOneIsValid && this.stepTwoIsValid) {
        this.currentStep = 3;
      }

      if (this.stepOneIsValid && this.stepTwoIsValid && this.stepThreeIsValid) {
        this.currentStep = 4;
        await this.$nextTick();
        setTimeout(() => {
          // this.$store.commit('showMenuNav', true);
          this.$store.commit('isFullAppView', false);
        }, 1200);
      }
    },
  },
  async created() {
    await this.$nextTick();
    await this.$store.commit('isFlatView', true);
    await this.$store.commit('showMenuNav', false);
    await this.$store.commit('isFullAppView', true);

    // eslint-disable-next-line prefer-destructuring
    this.isMainApp = (await this.$store.dispatch('evaluateScope'))[1];
    await this.$store.dispatch('deleteUserData', this.$store.getters.userId);
    const user = await this.$store.dispatch('getUserData', this.$store.getters.userId);
    const goals = this.isMentor ? this.$store.state.User.mentor.goals : this.$store.state.User.mentee.goals;

    this.user = Object.assign({}, user);

    this.form.bio = this.user.bio;
    this.form.photo = this.user.photo;
    this.form.goals = goals;
    this.form.course = this.user.course;
    this.form.py = this.user.py;

    await this.validateSteps();

    this.loading = false;
  },
  beforeDestroy() {
    this.$store.commit('showMenuNav', true);
    this.$store.commit('isFullAppView', false);
  },
};
</script>

<style>

</style>
