import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

import Auths from './Auths';
import User from './User';
import Open from "./Open";
import { cleanUrl, now } from '../modules/dataHelper';
import { apiPost, apiStore, generateFormData } from '../modules/apiHelper';

const routesIndexes = {
  'home': 1,
  'settings': 2,
  'profile': 3,
  'NewUser': 4,
  'ellyebby': 5,
  'chatView': 6,
  'FullTaskDetails': 7,
  'ResourceView': 8,
  'mentorMenteesView': 9,
  'mentorMenteeTasks': 10,
  'mentorMenteeChat': 11,
  'mentorMenteeProfile': 12,
  'menteeBio': 13,
  'menteeRequest': 14,
  'ReviewTask': 15,
  'mentorMenteeCreateTask': 16,
  'addResourcesToTask': 17,
  'license': 18,
  'TasksView': 19,
  'menteeMentorsView': 20,
  'TaskReport': 21,
  'mentorBio': 22,
  'MentorsSuggestionsList': 23,
  'mentorChat': 24,
  'addResourcesToTaskSubmit': 25,
  'GroupChat': 26,
  'resources': 27,
  'MatchEndedSurvey': 28,
  'RevertTask': 29,
  'FAQMenteeView': 30,
  'FAQMentorView': 31,
};

Vue.use(VueRouter);

const scope = store.dispatch('evaluateScope');

const mainAppRoutes = [
  {
    path: '/',
    name: 'landingpage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
  },
];

const scopeAppRoutes = [
  {
    path: '/',
    name: 'landingpage',
    component: () => import(/* webpackChunkName: "scopeviews" */ '../views/CompanyHome.vue'),
  },
  // {
  //   path: '/faq-mentor',
  //   name: 'FAQMentorPublicView',
  //   component: () => import(/* webpackChunkName: "scopeviews" */ '../views/FAQMentorPublic.vue'),
  // },
];

const invalidScopeRoute = [
  {
    path: '/',
    name: 'fakeScope',
    component: () => import(/* webpackChunkName: "auth3" */ '../views/Auths/FakeScope.vue'),
  },
];

const routes = async () => {
  const [isValidScope, isMainApp] = await scope;
  if (!isValidScope) return invalidScopeRoute;

  const globalRoutes = isMainApp ? mainAppRoutes : scopeAppRoutes;

  const [UserRoutes, authRoutes, openAppRoutes] = await Promise.all([
    User(),
    Auths(),
    Open(),
  ]);

  return [
    ...authRoutes,
    ...globalRoutes,
    ...UserRoutes,
    ...openAppRoutes,
  ];
};

const userData = store.dispatch('getLoggedInUserData');

const $router = async () => {
  // eslint-disable-next-line no-unused-vars
  const [isValidScope, isMainApp, isDevBuild, scopeId] = await scope;

  // if user is in a valid scope url
  // redirect user to the valid scope url
  // console.log(isValidScope, !isDevBuild);
  if (isValidScope && !isDevBuild) {
    const logUser = await userData;
    const userCompanyid = logUser[5];
    const userId = logUser[0];

    // evaluate only if user is logged in
    if (userId) {
      if (userCompanyid && Number(scopeId) !== Number(userCompanyid)) {
        // wrong scope
        // get user's company url
        const company = await store.dispatch('getCompany', [userCompanyid]);
        console.log('wrong scope::', company.website, '\n', company.id, '==', scopeId, '==', userCompanyid);

        // TODO: redirect user to the right scope url
        // let url = `${company.website}${window.location.pathname}`;
        // if (/(^http[s]?).+/g.test(url) === false) {
        //   url = `https://${url}`;
        // }

        // await store.dispatch('userLogOut');

        // window.location.replace(url);
      }
    }
  }

  const $routes = await routes();
  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: $routes,
  });

  // #region routecatchers

  // notification redirects
  // stores the routes to after-login-redirector
  // [Good for stats]
  router.beforeEach(async (to, from, next) => {
    const fullUrl = `${window.origin}/${to.path}`;
    const cleanedUrl = cleanUrl(fullUrl);
    // remove double tripple slash url
    if (/([^:])(\/{2,})/g.test(fullUrl)) {
      window.history.pushState(window.history.state, 'eMentored', cleanedUrl);
    }

    if (/(\/app\/n)(\/\S)/.test(cleanedUrl)) {
      const redirectTo = to.path.split(/(\/app\/n){1}/)[2];
      sessionStorage.setItem('after-login-redirector', `/app${redirectTo}`);
      next(`/app${redirectTo}`);
    } else {
      next();
    }
  });

  // login auth
  router.beforeEach(async (to, from, next) => {
    await userData;

    if (
      (to.matched.some(record => record.meta.requiresLogin) && !store.getters.isLoggedIn)
      || (to.matched.some(record => record.meta.requiresUserType) && !store.state.User.type)
    ) {
      // console.warn('AUTH ERROR!!!');
      store.commit('authError', 'You need to log in.');
      router.push('/login')
      // next('/login');
    } else {
      next();
    }
  });

  // mentor auth routes checker
  router.beforeEach(async (to, from, next) => {
    await userData;

    if (
      to.matched.some(record => record.meta.requiresUserType === 3) && store.state.User.type !== 3
    ) {
      // console.error('MENTOR AUTH ERROR!!!', to);
      // usertype mismatch. Login again
      store.commit('authError', 'You need to be a mentor to view this page.');
      router.push('/login')
      // next('/login');
    } else {
      next();
    }
  });

  // mentee auth route checker
  router.beforeEach(async (to, from, next) => {
    await userData;

    if (
      to.matched.some(record => record.meta.requiresUserType === 4) && store.state.User.type !== 4
    ) {
      // console.error('MENTEE AUTH ERROR!!!', to);
      // usertype mismatch. Login again
      store.commit('authError', 'You need to be a mentee to view this page.');
      next('/login');
    } else {
      next();
    }
  });

  // session expiry
  router.beforeEach((to, from, next) => {
    const ONE_HOUR = 3600000;

    apiStore.getItem('session-expiry').then((lastLoggedIn) => {
      if (!Number(lastLoggedIn)) return;

      const NOW = now();

      if (NOW - Number(lastLoggedIn) > ONE_HOUR) {
        console.log('log user out', NOW - lastLoggedIn);

        store.dispatch('userLogOut', 'Session expired. Please log in again').then(() => {
          next('/login');
        });
      } else {
        apiStore.setItem('session-expiry', NOW);
      }
    });

    next();
  });

  // stores previous route
  router.afterEach((to, from) => {
    // if route is unkown, redirect to app
    if (!to.name) {
      window.location.assign(`${window.location.origin}/${isValidScope ? 'app' : ''}`);
    }
    // tw-shadow-app navigation history
    if (from.name) {
      sessionStorage.setItem('previousroutename', from.name);
      sessionStorage.setItem('previousroutequery', JSON.stringify(from.query || {}));
      sessionStorage.setItem('previousrouteparams', JSON.stringify(from.params || {}));
    }
  });

  // log page navigations
  router.afterEach((to, from) => {
    const index = routesIndexes[to.name];

    if (!index || !store.state.User) {
      console.warn('Unloggable route ->>', to.name);
      return true;
    }

    // or parse 'activity_log' action and choose endpoint 3
    apiPost('activity_log', JSON.stringify({
      pageid: index,
      userid: store.state.User.id,
      companyid: store.state.User.companyid,
    }), 3, { 'Content-Type': 'application/json'}); // header is not being changed. dk why

  });

  // #endregion routecatchers

  return router;
};

export default $router;
