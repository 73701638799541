import localforage from 'localforage';
import {
  toBoolean, isStaleData, now,
} from './dataHelper';
import xhrRequest from './api/xhr';

/**
 *
 * @param {Boolean} isProduction ask if url is on production
 */
export function whichBuild(isProduction = true) {
  const url = window.location.hostname;
  const regexTest = new RegExp(isProduction ? process.env.VUE_APP_SCOPE_URL : process.env.VUE_APP_DEV_APP_URL);

  return !!(regexTest.test(url));
}

export const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
export const apiUrl = `${apiBaseUrl}${whichBuild(true) ? '' : '/test'}`;
export const apiProcessUrl = `${apiUrl}/process/`;
export const apiTstUrl = `${apiUrl}/tst/`;
export const apiTaskUrl = `${apiUrl}/task/`;
export const apiChatUrl = `${apiUrl}/chat/`;
export const apiTsdUrl = `${apiUrl}/tsd/`;
export const apiTslAccUrl = `${apiUrl}/tsl_account/`;
export const apiTslMssgUrl = `${apiUrl}/tsl_mssg/`;
export const apiTslCodeUrl = `${apiUrl}/tsl_code/`;
export const apiTslStatUrl = `${apiUrl}/tsl_stats/`;
export const apiCreateUrl = `${apiUrl}/create/`;
export const apiTslSessUrl = `${apiUrl}/tsl_session/`;
export const apiTslSettUrl = `${apiUrl}/tsl_settings/`;
export const apiSchlUrl = `${apiUrl}/schl/`;
export const apiPackUrl = `${apiUrl}/pack/`;
export const apiPyUrl = `${apiUrl}/py/`;
export const apiTslKenUrl = `${apiUrl}/tsl_ken/`;
export const apiActivityLog = `${apiUrl}/activity_log/`;
export const apiTslKenResource = `${apiUrl}/tsl_ken_resource/`;
export const apiTslKop = `${apiUrl}/tsl_kop/`;
export const apiAddResourcePillar = `${apiUrl}/add_resource_pillar/`;
export const apiTslKenRs = `${apiUrl}/tsl_ken_rs/`;
export const apiTslKenRsRc = `${apiUrl}/tsl_ken_rsrc/`;
export const apiTslKops = `${apiUrl}/tsl_kops/`;
export const apiChatGroup = `${apiUrl}/chat_group/`;
export const apiTslKenCht = `${apiUrl}/tsl_ken_cht/`;
export const apiTslKenRsr = `${apiUrl}/tsl_ken_rsr/`;
export const apiTslKenRsRb = `${apiUrl}/tsl_ken_rsrb/`;
export const apiTslKenBk = `${apiUrl}/tsl_ken_bk/`;
export const apiTslKenChtMent = `${apiUrl}/tsl_ken_cht_ment/`;
export const apiChatGroupMent = `${apiUrl}/chat_group_ment/`;
export const apiTslKenLinksLogMentor = `${apiUrl}/tsl_ken_links_log_mentor/`;
export const apiTslKenLinksLogMentee = `${apiUrl}/tsl_ken_links_log_mentee/`;
export const apiTslKenLinksLog = `${apiUrl}/tsl_ken_links_log/`;
export const apiTslKenProd = `${apiUrl}/tsl_ken_prod/`;
export const apiTslKenGoalFilter = `${apiUrl}/tsl_ken_goal_filter/`;
export const apiTslKenResourceADel = `${apiUrl}/tsl_ken_resource_a_del/`;
export const apiTslKenSuvCrt = `${apiUrl}/tsl_ken_suv_crt/`;
export const apiTslKenGoalStats = `${apiUrl}/tsl_ken_goal_stats/`;
export const apiTslKenSuvLog = `${apiUrl}/tsl_ken_suv_log/`;
export const apiTslKenSuv = `${apiUrl}/tsl_ken_suv/`;
export const apiTslKenResourceA = `${apiUrl}/tsl_ken_resource_a/`;
export const apiTslKenPil = `${apiUrl}/tsl_ken_pil/`;
export const apiTslKenUp = `${apiUrl}/tsl_ken_up/`;
export const apiTslKenUpt = `${apiUrl}/tsl_ken_upt/`;
export const apiTslKenResourceCompLog = `${apiUrl}/tsl_ken_resource_comp_log/`;
export const apiTslKenTasks = `${apiUrl}/tsl_ken_tasks/`;
export const apiTslKenMt = `${apiUrl}/tsl_ken_mt/`;
export const apiTslKenMntA = `${apiUrl}/tsl_ken_mnt_a/`;
export const apiTslKenMntB = `${apiUrl}/tsl_ken_mnt_b/`;
export const apiTslKenMntC = `${apiUrl}/tsl_ken_mnt_c/`;
export const apiTslKenGrpC = `${apiUrl}/tsl_ken_grp_c/`;
export const apiTslKenGrp = `${apiUrl}/tsl_ken_grp/`;
export const apiTslKenGrpB = `${apiUrl}/tsl_ken_grp_b/`;
export const apiTslKenGrpChatGroup = `${apiUrl}/tsl_ken_grp_chat_group/`;
export const apiTslKenGrpD = `${apiUrl}/tsl_ken_grp_d/`;
export const apiTslKenGrpT = `${apiUrl}/tsl_ken_grp_t/`;
export const apiTslKenJan = `${apiUrl}/tsl_ken_jan/`;
export const apiTslKenGoal = `${apiUrl}/tsl_ken_goal/`;
export const apiTslFelCrtA = `${apiUrl}/tsl_fel_crt_a/`;
export const apiTslFelMt = `${apiUrl}/tsl_fel_mt/`;
export const apiTslFelCrt = `${apiUrl}/tsl_fel_crt/`;
export const apiTslFelQ = `${apiUrl}/tsl_fel_q/`;

export const apiStore = localforage.createInstance({
  driver: [
    localforage.INDEXEDDB,
    localforage.WEBSQL,
    localforage.LOCALSTORAGE,
  ],
  name: 'ema-app',
  storeName: 'lowdata',
  version: 3,
});

export function getlocalStoreArrayItem(key) {
  return apiStore.getItem(key).then(value => JSON.parse(value));
}

/**
 * generate api endpoint url
 * @param {String} endpoint [process, tst, task, _custom_, chat, tsl_account, tsd, tsl_mssg, tsl_code, tsl_stat, create, tsl_session, tsl_settings, schl, pack, py, tsl_ken, activity_log, tsl_ken_resource, tsl_kop, add_resource_pillar, tsl_ken_rs, tsl_ken_rsrc, tsl_kops, chat_group, tsl_ken_cht, tsl_ken_rsr, tsl_ken_rsrb, tsl_ken_bk tsl_ken_cht_ment, chat_group_ment, tsl_ken_links_log_mentor, tsl_ken_links_log_mentee, tsl_ken_links_log, tsl_ken_prod, tsl_ken_goal_filter, tsl_ken_resource_a_del, tsl_ken_suv_crt, tsl_ken_goal_stats, tsl_ken_suv_log, tsl_ken_suv, tsl_ken_resource_a, tsl_ken_pil, tsl_ken_up, tsl_ken_upt, tsl_ken_resource_comp_log, tsl_ken_tasks, tsl_ken_mt, tsl_ken_mnt_a, tsl_ken_mnt_b, tsl_ken_mnt_c, tsl_ken_grp_c, tsl_ken_grp, tsl_ken_grp_b, tsl_ken_grp_chat_group, tsl_ken_grp_t, tsl_ken_grp_d, tsl_ken_jan, tsl_ken_goal]
 default: process
 * @param {String} action
 * @param {Object} cache cache for how long? { maxAge: _ms_ } default: 1 hour
 */
export function buildApiEndpoint(action, endpoint) {
  switch (endpoint) {
    case 0:
      return `${apiProcessUrl}?action=${action}`;
    case 1:
      return `${apiTstUrl}?action=${action}`;
    case 2:
      return `${apiTaskUrl}?action=${action}`;
    case 3:
      return `${apiUrl}/${action}/`; // _custom_
    case 4:
      return `${apiChatUrl}?action=${action}`;
    case 5:
      return `${apiTslAccUrl}?action=${action}`;
    case 6:
      return `${apiTsdUrl}?action=${action}`;
    case 7:
      return `${apiTslMssgUrl}?action=${action}`;
    case 8:
      return `${apiTslCodeUrl}?action=${action}`;
    case 9:
      return `${apiTslStatUrl}?action=${action}`;
    case 10:
      return `${apiCreateUrl}?action=${action}`;
    case 11:
      return `${apiTslSessUrl}?action=${action}`;
    case 12:
      return `${apiTslSettUrl}?action=${action}`;
    case 13:
      return `${apiSchlUrl}?action=${action}`;
    case 14:
      return `${apiPackUrl}?action=${action}`;
    case 15:
      return `${apiPyUrl}?action=${action}`;
    case 16:
      return `${apiTslKenUrl}?action=${action}`;
    case 17:
      return apiActivityLog;
    case 18:
      return `${apiTslKenResource}?action=${action}`;
    case 19:
      return `${apiTslKop}?action=${action}`;
    case 20:
      return apiAddResourcePillar;
    case 21:
      return `${apiTslKenRs}?action=${action}`;
    case 22:
      return `${apiTslKenRsRc}?action=${action}`;
    case 23:
      return `${apiTslKops}?action=${action}`;
    case 24:
      return `${apiChatGroup}?action=${action}`;
    case 25:
      return `${apiTslKenCht}?action=${action}`;
    case 26:
      return `${apiTslKenRsr}?action=${action}`;
    case 27:
      return `${apiTslKenRsRb}?action=${action}`;
    case 28:
      return `${apiTslKenBk}?action=${action}`;
    case 29:
      return `${apiTslKenChtMent}?action=${action}`;
    case 30:
      return `${apiChatGroupMent}?action=${action}`;
    case 31:
      return `${apiTslKenLinksLogMentor}?action=${action}`;
    case 32:
      return `${apiTslKenLinksLogMentee}?action=${action}`;
    case 33:
      return `${apiTslKenLinksLog}?action=${action}`;
    case 34:
      return `${apiTslKenProd}?action=${action}`;
    case 35:
      return `${apiTslKenGoalFilter}?action=${action}`;
    case 36:
      return `${apiTslKenResourceADel}?action=${action}`;
    case 37:
      return `${apiTslKenSuvCrt}?action=${action}`;
    case 38:
      return `${apiTslKenGoalStats}?action=${action}`;
    case 39:
      return `${apiTslKenSuvLog}?action=${action}`;
    case 40:
      return `${apiTslKenSuv}?action=${action}`;
    case 41:
      return `${apiTslKenResourceA}?action=${action}`;
    case 42:
      return `${apiTslKenPil}?action=${action}`;
    case 43:
      return `${apiTslKenUp}?action=${action}`;
    case 44:
      return `${apiTslKenUpt}?action=${action}`;
    case 45:
      return `${apiTslKenResourceCompLog}?action=${action}`;
    case 46:
      return `${apiTslKenTasks}?action=${action}`;
    case 47:
      return `${apiTslKenMt}?action=${action}`;
    case 48:
      return `${apiTslKenMntA}?action=${action}`;
    case 49:
      return `${apiTslKenMntB}?action=${action}`;
    case 50:
      return `${apiTslKenMntC}?action=${action}`;
    case 51:
      return `${apiTslKenGrpC}?action=${action}`;
    case 52:
      return `${apiTslKenGrp}?action=${action}`;
    case 53:
      return `${apiTslKenGrpB}?action=${action}`;
    case 54:
      return `${apiTslKenGrpChatGroup}?action=${action}`;
    case 55:
      return `${apiTslKenGrpT}?action=${action}`;
    case 56:
      return `${apiTslKenGrpD}?action=${action}`;
    case 57:
      return `${apiTslKenJan}?action=${action}`;
    case 58:
      return `${apiTslKenGoal}?action=${action}`;
    case 59:
      return `${apiTslFelCrtA}?action=${action}`;
    case 60:
      return `${apiTslFelMt}?action=${action}`;
    case 61:
      return `${apiTslFelCrt}?action=${action}`;
    case 62:
      return `${apiTslFelQ}?action=${action}`;
    default:
      return `${apiProcessUrl}?action=${action}`;
  }
}

/**
 * #### make an ajax GET request (uses xhr.js)
 * @param {String} actionParam api action name
 * @param {Number} endpoint [process, tst, task, custom, chat, tsl_account, tsd, tsl_mssg, tsl_code, tsl_stat, create, tsl_session, tsl_settings, schl, pack, py, tsl_ken, activity_log, tsl_ken_resource, tsl_kop, add_resource_pillar, tsl_ken_rs, tsl_ken_rsrc, tsl_kops, chat_group, tsl_ken_cht, tsl_ken_rsr, tsl_ken_rsrb, tsl_ken_bk, tsl_ken_cht_ment, chat_group_ment, tsl_ken_links_log_mentor, tsl_ken_links_log_mentee, tsl_ken_links_log, tsl_ken_prod, tsl_ken_goal_filter, tsl_ken_resource_a_del, tsl_ken_suv_crt, tsl_ken_goal_stats, tsl_ken_suv_log, tsl_ken_suv, tsl_ken_resource_a, tsl_ken_pil, tsl_ken_up, tsl_ken_upt, tsl_ken_resource_comp_log, tsl_ken_tasks, tsl_ken_mt, tsl_ken_mnt_a, tsl_ken_mnt_b, tsl_ken_mnt_c, tsl_ken_grp_c, tsl_ken_grp, tsl_ken_grp_b, tsl_ken_grp_chat_group, tsl_ken_grp_t, tsl_ken_grp_d, tsl_ken_jan, tsl_ken_goal] default: tsd
 * @param {Object} extraParam request queries {key: value} -> ?[key]=[value]&...
 * @param {Boolean} refresh whether to bypass cached results/fetch from server default: false
 * @param {Boolean} cacheData whether to cache the data default: true
 */
// eslint-disable-next-line consistent-return
export async function apiGet(
  actionParam = '',
  endpoint = 0,
  extraParam = {},
  refresh = false,
  cacheData = true,
) {
  try {
    const storeName = `${endpoint}/${actionParam}/${JSON.stringify(extraParam)
      .slice(0, 1)
      .slice(-1, -2)
      .replace('-', '_')
      .replace(' ', '_')}`;
    const storeData = (await apiStore.getItem(storeName) || undefined);

    if (Array.isArray(storeData) && !refresh) {
      if (!isStaleData(storeData[0])) return storeData[1];
    } else {
      // clean api cache
      await apiStore.removeItem(storeName);
    }

    const url = buildApiEndpoint(actionParam, endpoint);
    if (!url) throw new Error('BAD GET API URL');

    return new Promise((resolve, reject) => {
      xhrRequest({
        url,
        method: 'GET',
        params: {
          ...extraParam,
        },
      }).catch((err) => {
        console.warn('AJAX GET ERROR: ', actionParam);
        reject(err);
      }).then((res) => {
        // validate and save the data
        if (typeof res === 'object') {
          // eslint-disable-next-line no-param-reassign
          res.data.error = toBoolean(res.data.error);
          // eslint-disable-next-line no-param-reassign
          delete res.config;
          // eslint-disable-next-line no-param-reassign
          delete res.request;

          if (cacheData) {
            apiStore.setItem(storeName, [now(), res]);
          }
        }

        resolve(res);
      });
    });
  } catch (err) {
    console.warn(err);
  }
}


/**
 * Make an ajax POST request. (uses ./api/xhr.js)
 * @param {String} action api action name
 * @param {FormData} data
 * @param {Number} endpoint [process, tst, task, _custom_, chat, tsl_account, tsd, tsl_mssg, tsl_code, tsl_stat, create, tsl_session, tsl_settings, schl, pack, py, tsl_ken, activity_log, tsl_ken_resource, tsl_kop, add_resource_pillar, tsl_ken_rs, tsl_ken_rsrc, tsl_kops, chat_group, tsl_ken_cht, tsl_ken_rsr, tsl_ken_rsrb, tsl_ken_bk, tsl_ken_cht_ment, chat_group_ment, tsl_ken_links_log_mentor, tsl_ken_links_log_mentee, tsl_ken_links_log, tsl_ken_prod, tsl_ken_goal_filter, tsl_ken_resource_a_del, tsl_ken_suv_crt, tsl_ken_goal_stats, tsl_ken_suv_log, tsl_ken_suv, tsl_ken_resource_a, tsl_ken_pil, tsl_ken_up, tsl_ken_upt, tsl_ken_resource_comp_log, tsl_ken_tasks, tsl_ken_mt, tsl_ken_mnt_a, tsl_ken_mnt_b, tsl_ken_mnt_c, tsl_ken_grp_c, tsl_ken_grp, tsl_ken_grp_b, tsl_ken_grp_chat_group, tsl_ken_grp_t, tsl_ken_grp_d, tsl_ken_jan, tsl_ken_goal] default: process
 * @param {Object} headers
 * @param {Object} cache cache for how long? { maxAge: _ms_ } default: 30 minutes
 */
// eslint-disable-next-line consistent-return
export async function apiPost(
  action,
  data,
  endpoint = 0,
  headers = { 'Content-Type': 'multipart/form-data' },
  progressCallback = undefined,
) {
  if (!action || !data) {
    console.error('action or data not set');
    return false;
  }

  try {
    const url = buildApiEndpoint(action, endpoint);

    if (!url) throw new Error('BAD POST API URL');

    return xhrRequest({
      url,
      method: 'POST',
      data,
      headers,
      onUploadProgress: progressCallback,
    }).catch(() => {
      console.warn('AJAX POST ERROR: ', action);
    });
  } catch (err) {
    console.warn(err);
  }
}

/**
 * Does not support files YET
*/
export function generateFormData(obj = {}) {
  const formData = new FormData();

  Object.entries(obj).forEach(([index, value]) => {
    if (typeof value !== 'undefined' || value !== 'null') {
      formData.set(index, value);
    }
  });

  return formData;
}
